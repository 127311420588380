import { useCatalogTranslation } from './use-catalog-translations';
import {
  Emoji,
  List,
  Cart,
  Import,
  User,
  Home,
  KanbanBoard,
} from 'iconoir-react';
import { useSellerAdminSettings } from './use-settings';
import { useAccount } from '../context/account-context';
import { useMemo } from 'react';
import { useIsDemoEnv } from './use-is-demo-env';

type NavigationItem = {
  label: string;
  icon: React.ReactNode;
  link: string;
  anchor: string;
};

export const useNavigation = () => {
  const { ct } = useCatalogTranslation('admin_common');
  const { SellerFrontSettings } = useSellerAdminSettings();
  const { ...loggedUser } = useAccount();
  const { isDemoEnv } = useIsDemoEnv();
  const navigation: NavigationItem[] = useMemo(() => {
    const nav = [
      {
        label: ct('CommonNavItem.home', 'Accueil'),
        icon: <Home />,
        link: '/',
        anchor: 'home',
      },
      {
        label: ct('CommonNavItem.smartOrders', 'Smart Orders'),
        icon: <KanbanBoard />,
        link: '/smartorder',
        anchor: 'smartorder',
      },
    ];

    if (!isDemoEnv) {
      nav.push(
        {
          label: ct('CommonNavItem.products', 'Produits'),
          icon: <List />,
          link: '/products',
          anchor: 'products',
        },
        {
          label: ct('CommonNavItem.customers', 'Clients'),
          icon: <Emoji />,
          link: '/customers',
          anchor: 'customers',
        },
        {
          label: ct('CommonNavItem.orders', 'Commandes'),
          icon: <Cart />,
          link: '/orders',
          anchor: 'orders',
        },
      );
    }

    if (loggedUser?.role === 'admin') {
      nav.push({
        label: ct('CommonNavItem.users', 'Utilisateurs'),
        icon: <User />,
        link: '/users',
        anchor: 'users',
      });
    }

    if (SellerFrontSettings?.displayActionsNavigationIcon) {
      nav.push({
        label: ct('CommonNavItem.actions', 'Actions'),
        icon: <Import />,
        link: '/actions',
        anchor: 'actions',
      });
    }

    return nav;
  }, [
    loggedUser,
    SellerFrontSettings?.displayActionsNavigationIcon,
    isDemoEnv,
  ]);

  return { navigation } as const;
};
