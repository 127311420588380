import React from 'react';
import IconProps from '@/lib/types/icon';

const LogoIcon = ({
  height = 32,
  width = 32,
  color = 'currentColor',
  onClick = () => {},
  ...attributes
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0656 24C26.2385 24 28 22.2385 28 20.0656L28 10.9935C28 7.57294 25.2271 4.8 21.8065 4.8L11.68 4.8L6.43584 0.915436C5.63324 0.320915 4.66086 -1.33024e-07 3.66204 -2.20343e-07C1.86047 -3.77842e-07 0.400002 1.46047 0.400001 3.26204L0.400001 11.2C0.399998 15.6183 3.98172 19.2 8.4 19.2L16.72 19.2L22.5044 23.4847C22.9562 23.8194 23.5035 24 24.0656 24ZM16.72 19.2L23.2 19.2L23.2 9.6C19.9268 9.6 16.7402 8.54834 14.1101 6.60005L11.68 4.8L5.2 4.8L5.2 14.4C8.47318 14.4 11.6597 15.4517 14.2899 17.4L16.72 19.2Z"
        fill="#212020"
      />
    </svg>
  );
};

export default LogoIcon;
