'use client';

import React from 'react';
import { useCatalogTranslation } from '@hooks/use-catalog-translations';
import { useRouter } from 'next/navigation';
import { LogOut } from 'iconoir-react';

const LogoutButton = ({ full = true }: { full: boolean }) => {
  const { ct } = useCatalogTranslation('admin_common');

  const router = useRouter();
  const goToLogoutPage = () => {
    router.push('/logout');
  };
  return (
    <button
      data-testid="logout-button"
      className="text-error-600 text-sm font-body"
      onClick={goToLogoutPage}
    >
      {full ? ct('Common.logout', 'Se déconnecter') : <LogOut />}
    </button>
  );
};

export default LogoutButton;
